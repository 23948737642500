import "./styles.less";

import { FC } from "react";
import { encodeHashUrl } from "../../Utils/Functions";
import { useRouter } from "next/router";

export interface LinkProps {
	pathname?: string;
	data?: any;
	routeVars?: any;
	as?: string;
	avoidRouter?: boolean;
	callback?: () => void | null;
	isExternal?: boolean;
	isBlank?: boolean;
	children?:any
}

export const PageLink: FC<LinkProps> = ({
	pathname,
	data = {},
	routeVars,
	as,
	children,
	avoidRouter,
	callback = null,
	isExternal = false,
	isBlank = false,
}) => {
	const router = useRouter();

	const onClick = e => {
		if (e.ctrlKey || e.metaKey) return false;
		if (!avoidRouter && !isExternal) {
			e.preventDefault();

			if (isBlank) {
				return window.open(`/${as}`, "_blank");
			}

			router.push(
				{
					pathname: pathname,
					query: { hashed: encodeHashUrl(data), ...routeVars },
				},
				"/" + as
			);
		}

		if (typeof callback == "function") callback();

		if (isExternal) {
			e.preventDefault();
			window.open(
				as,
				"_blank",
				"location=yes,height=670,width=620,scrollbars=yes,status=yes"
			);
		}
	};

	return (
		<a
			className="containerLink"
			onClick={onClick}
			href={isExternal ? "#" : "/" + as}
			target="_blank"
			title={data.title}
		>
			{children}
		</a>
	);
};
