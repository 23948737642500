import "./styles.less";

import { PageLink } from "../Link/web";
import React from "react";
import { useTheme } from "../../Styles/ThemeHook";

export function Logo({
	type = "auto",
	link = false,
}: {
	type?: "auto" | "desktop" | "mobile";
	link?: boolean;
}) {
	const { theme } = useTheme();

	return <LogoWrapper link={link}>
		{(type=='auto' || type=='mobile') && 
			<img src={theme.images.isotipo} alt="Logo" className={`logo mobile type_${type}`}/>
		}
		{(type=='auto' || type=='desktop') && 
			<img src={theme.images.logo} alt="Logo" className={`logo desktop type_${type}`}/>
		}
	</LogoWrapper>
	

}


const LogoWrapper = ({link=false,children}) => link 
	? <PageLink pathname="/home" as={""}>
			{children}
		</PageLink>
	: <>
		{children}
	</>
